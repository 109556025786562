.impressum {
    min-height: 100vh;
    background-color: rgb(13, 79, 120);
    color: white;
    display: flex;
    flex-direction: column;
}

.impressum-title {
    padding: 10rem 0 2rem 0;
    width: 100%;
    text-align: center;
}

.impressum-title > h1 {
    color: rgb(251, 188, 4);
    padding-bottom: 5rem;
    font-family: "Bitter Bold", sans-serif;
    font-size: 56px;
    letter-spacing: 10px;
    line-height: 56px;
    font-weight: 500;
}

.impressum-text {
    padding: 0 5rem;
    display: flex;
    justify-content: space-between;
}

.impressum-text-left,
.impressum-text-right {
    width: 50%;
}

.impressum-text-left-p > h1{
    margin: 1rem 0;
    font-family: "Coolvetica", sans-serif;
    font-size: 32px;
    font-weight: 500;
}

.impressum-text-left-p > p{
    margin-bottom: 0.2rem;
}

.impressum-text-right-p > h1{
    margin: 1rem 0;
    font-family: "Coolvetica", sans-serif;
    font-size: 32px;
    font-weight: 500;
}

.impressum-text-right-p > p{
    margin-bottom: 0.2rem;
}

.impressum-text-right-p > p > a {
    color: rgb(251, 188, 4);
    text-decoration: none;
}

.impressum-text-right-p > p > a:hover{
    color: rgb(211, 158, 0);
}

@media (max-width: 35em) {
    .impressum-logo-img {
        padding-top: 2rem;
        width: 15rem;
    }

    .impressum-title {
        width: auto;
        text-align: center;
    }
    
    .impressum-title > h1 {
        font-size: 1.3rem;
        letter-spacing: 2px;
    }

    .impressum-text {
        padding: 0 2rem 2rem 2rem;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .impressum-text-left,
    .impressum-text-right {
        width: auto;
    }

    .impressum-text-left-p > h1,
    .impressum-text-right-p > h1 {
        font-size: 1.5rem;
        text-align: center;
    }

    .impressum-text-left-p > p,
    .impressum-text-right-p > p{
        font-size: 0.9rem;
    }
}