.top-bar {
    position: absolute;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.5rem 0;
    background-color: rgb(13, 79, 120);
    border-bottom: 10px solid white;
    z-index: 999;
}

.top-bar-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-bar-logo > img {
    width: 27rem;
}

.top-bar-nav {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    gap: 2rem;
}

.top-bar-nav > li{
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 500;
}

.top-bar-nav > li:hover {
    color: rgb(251, 188, 4);
    transform: scale(1.2);
    transition: transform 0.5s;
}

.nav-button {
    cursor: pointer;
    color: white;
}

.top-bar-nav-mob {
    width: 100%;
    text-align: end;
    padding: 0 2rem;
    text-align: end;
    overflow: hidden;
}

.top-bar-nav-mob > ul {
    overflow-x: hidden;
    list-style: none;
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(1rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    animation: openMenu 0.5s ease-in-out;
}

.top-bar-nav-mob > ul > li {
    width: 100%;
    background-color: white;
    border-bottom: 2px solid rgb(251, 188, 4);
    color: rgb(13, 79, 120);
}

.top-bar-nav-mob > ul > li > span{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
}

@keyframes openMenu {
    0%{
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media (max-width: 85em) {
    .top-bar-logo > img {
        width: 25rem;
    }
    .top-bar-nav {
        gap: 1rem;
    }
    .top-bar-nav > li {
        font-size: 1.1rem;
    }
}

@media (max-width: 74em) {
    .top-bar-logo > img {
        width: 23rem;
    }
    .top-bar-nav {
        gap: 1rem;
    }
    .top-bar-nav > li {
        font-size: 1rem;
    }
}

@media (max-width: 64em) {
    .top-bar-logo > img {
        width: 20rem;
    }
    .top-bar-nav {
        gap: 0.9rem;
    }
    .top-bar-nav > li {
        font-size: 0.9rem;
    }
}

@media (max-width: 35em) {
    .top-bar-logo {
        padding: 0.5rem;
    }
    .top-bar-logo > img {
        width: 75vw;
    }
}


