.App {
    overflow-x: hidden;
}

.fade-up {
    animation: fadeUp 1.5s linear;
}

.fade-up-delay1 {
    animation: fadeUpDelay1 2s ease-in-out; 
}

.fade-up-delay2 {
    animation: fadeUpDelay2 3s ease-in-out; 
}

.fade-up-delay3 {
    animation: fadeUpDelay2 4s ease-in-out; 
}

.fade-right {
    animation: fadeRight 1.5s linear;
}

.fade-left {
    animation: fadeLeft 1.5s linear;
}

.fade-down {
    animation: fadeDown 1.5s linear;
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(50%);
    }
    33% {
        opacity: 0;
        transform: translateY(50%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes fadeUpDelay1 {
    0% {
        opacity: 0;
        transform: translateY(50%);
    }
    50% {
        opacity: 0;
        transform: translateY(50%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes fadeUpDelay2 {
    0% {
        opacity: 0;
        transform: translateY(50%);
    }
    66% {
        opacity: 0;
        transform: translateY(50%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes fadeUpDelay3 {
    75% {
        opacity: 0;
        transform: translateY(50%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes fadeRight {
    0% {
        opacity: 0;
        transform: translateX(-50%);
    }
    33% {
        opacity: 0;
        transform: translateX(-50%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes fadeLeft {
    0% {
        opacity: 0;
        transform: translateX(50%);
    }
    33% {
        opacity: 0;
        transform: translateX(50%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes fadeDown {
    0% {
        opacity: 0;
        transform: translateY(-50%);
    }
    33% {
        opacity: 0;
        transform: translateY(-50%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}
