.admin-drsqna {
    width: 60rem;
    border-bottom: 1px solid white;
    gap: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.admin-drs-q {
    margin-top: 1rem;
    width: 100%;
    padding: 0.5rem;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
}

.admin-drs-q > input {
    width: calc(100% - 1.2rem);
    font-size: 1.3rem;
    padding: 0.3rem 0.5rem;
}

.admin-drs-q > button {
    font-size: 1.3rem;
    padding: 0.3rem 0.5rem;
    width: fit-content;
}

.admin-drs-q > span {
    font-size: 2rem;
}

.admin-drs-a {
    width: 90%;
    padding: 0.5rem;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.admin-drs-a > textarea {
    width: calc(100% - 1.2rem);
    resize: none;
    font-size: 1.2rem;
    padding: 0.3rem 0.5rem;
}

.admin-drs-a > button {
    font-size: 1.3rem;
    padding: 0.3rem 0.5rem;
    width: fit-content;
}

.admin-drs-a > p {
    font-size: 1.1rem;
}

@media (max-width: 35em) {
    .admin-drsqna {
        width: 90%;
    }

    .admin-drs-q > span {
        font-size: 1.5rem;
    }

    .admin-drs-a > textarea {
        width: calc(100% - 1.2rem);
        resize: none;
        font-size: 1rem;
        padding: 0.3rem 0.5rem;
    }
    
    .admin-drs-a > p {
        font-size: 1rem;
    }
}