.admin-top-bar {
    border-top: 7rem solid rgb(13, 79, 120);
    padding: 0.5rem 0;
}

.admin-top-bar-nav {
    color: rgb(13, 79, 120);
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    gap: 1rem;
}

.admin-top-bar-nav > li{
    border: 1px solid black;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.admin-top-bar-nav > li > input{
    max-width: 8rem;
    padding: 0.1rem;
}

.admin-top-bar-nav > li > button {
    width: fit-content;
    padding: 0.2rem;
}

.admin-top-bar-nav > li > span {
    text-align: center;
}

.admin-nav-button {
    display: flex;
    justify-content: end;
    cursor: pointer;
    padding: 0.7rem;
}

.admin-top-bar-nav-mob > ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    font-weight: 500;
}

.admin-top-bar-nav-mob > ul > li{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
    border-bottom: 2px solid black;
    padding: 1rem;
}

.admin-top-bar-nav-mob > ul > li > button {
    padding: 0.2rem 0.4rem;
}

.admin-top-bar-nav-mob > ul > li > input {
    width: 40%;
    padding: 0.2rem 0.4rem;
}