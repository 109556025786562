.admin-login {
    background-color: rgb(13, 79, 120);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 1rem black;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: start;
}

.login-card > button {
    cursor: pointer;
    background-color: rgb(251, 188, 4);
    color: black;
    font-weight: bold;
    font-size: 1.5rem;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 0.7rem 2rem;
}

.login-card > input {
    padding: 0.5rem;
    font-size: 1.5rem;
}

.login-card > a {
    color: rgb(13, 79, 120);
    font-weight: 500;
    text-decoration: none;
    font-size: 1.5rem;
}

.login-card > a:hover {
    text-decoration: underline;
}