.add-new-font {
    position: absolute;
    top: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-new-font > button {
    cursor: pointer;
    border: none;
    outline: none;
    background-color: rgb(251, 188, 4);
    padding: 0.5rem 0.7rem;
    font-size: 1.2rem;
    color: black;
}

.add-new-font > button:hover {
    background-color: rgb(218, 163, 0);
}

.add-new-font-menu {
    position: absolute;
    top: -25rem;
    width: 99vw;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20rem 0 50rem 0;
    z-index: 10000;
}

.add-new-font-window {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    box-shadow: 0 0 1rem rgb(251, 188, 4);
    border-radius: 10px;
    overflow: hidden;
    height: 31rem;
}

.add-new-font-nav {
    color: black;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-font-menu {
    cursor: pointer;
    position: absolute;
    top: 2%;
    right: 1%;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1.5rem;
    padding: 0.2rem 0.4rem;
    color: black;
    border-radius: 2px;
}

.close-font-menu:hover{
    background-color: rgb(13, 79, 120);
    color: white;
}

.add-new-font-nav > ul {
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: start;
}

.add-new-font-nav > ul > li{
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 ;
    font-size: 1.5rem;
}

.add-new-font-nav > ul > li:hover{ 
    background-color: lightgray;
}

.active-font-nav {
    border-bottom: 4px solid rgb(13, 79, 120);
}

.add-new-font-body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.search-font {
    width: 80%;
}

.search-font > input {
    padding: 0.2rem 0.5rem;
    font-size: 1.3rem;
    width: 100%;
}

.display-all-fonts {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    border-right: 1px solid black;
    width: 50%;
    height: 27rem;
}

.all-fonts {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    max-height: 55vh;
    width: 100%;
    overflow-y: scroll;
}

.all-fonts > span {
    padding: 0.3rem 0.5rem;
    width: 95%;
    border-bottom: 1px solid black;
    cursor: pointer;
}

.all-fonts > span:hover {
    background-color: rgb(13, 79, 120);
    color: white;
}
 
.test-font {
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    color: black;
}

.test-font > span {
    margin-bottom: -2rem;
}

.test-font > input {
    font-size: 1.3rem;
    padding: 0.2rem 0.5rem;
}

.test-font > p {
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
}

.test-font > button {
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 1.3rem;
    padding: 0.3rem 0.5rem;
    background-color: rgb(251, 188, 4);
}

.test-font > button:hover {
    background-color: rgb(214, 163, 8);
}

.my-fonts {
    width: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 25rem;
}

.select-my-font {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 21rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.select-font {
    cursor: pointer;
    width: 100%;
    border-bottom: 2px solid black;
    text-align: center;
    font-size: 1.3rem;
    padding: 0.5rem 0;
}

.select-font:hover {
    background-color: rgb(13, 79, 120);
    color: white;
}

.slctd {
    background-color: rgb(13, 79, 120);
    color: white;
}

.my-fonts > button {
    cursor: pointer;
    background-color: rgb(251, 188, 4);
    border: none;
    outline: none;
    padding: 0.3rem 1rem;
    font-size: 1.3rem;
    border-radius: 5px;
}

.my-fonts > button:hover {
    background-color: rgb(207, 158, 11);;
}

@media (max-width: 45em) {
    .add-new-font {
        top: 3%;
        left: 0;
        right: 0;
    }

    .add-new-font-window {
        width: 80%;
        height: auto;
    }

    .add-new-font-body {
        flex-direction: column;
    }

    .display-all-fonts {
        border-bottom: 1px solid black;
        width: 100%;
        height: 20rem;
    }
}