.change-title-wrap-service {
    padding: 0.5rem;
    border: 1px solid gray;
}

.change-text-service {
    padding: 0.5rem;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.change-text-service > label {
    font-weight: 500;
    color: black;
    font-size: 1.4rem;
}

.change-text-service > textarea {
    font-size: 1.1rem;
    padding: 0.2rem 0.4rem;
}

.change-text-service > button {
    width: fit-content;
    padding: 0.5rem;
    font-weight: 500;
    color: black;
    font-size: 1.2rem;
}