.drs-carousel {
    max-width: 90rem;
    margin-inline: auto;
}

.drs-carousel-comment-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 0;
    border-radius: 5px;
}

.drs-carousel-comment-user {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
}

.drs-carousel-comment-name > p{
    color: white;
}

.drs-carousel-comment-name > span {
    color: rgb(183, 183, 183);
}

.drs-carousel-comment-rating {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rating {
    font-size: 2rem;
    color: white;
}

.drs-carousel-comment-text {
    text-align: center;
    color: white;
    font-size: 1.5rem;
}

.drs-carousel-comment-name {
    color: rgb(221, 221, 221);
}

.slick-slide > div {
    margin: 0 10px;
}

.slick-prev:before {
    color: white;
}

.slick-next:before {
    color: white;
}

.slick-dots li.slick-active button:before {
    color: white;
}

.slick-dots > li > button {
    color: white;
}

.slick-dots > li > button:before {
    color: white;
}

@media (max-width: 35em) {
    .drs-carousel {
        width: 100%;
    }

    .drs-carousel-comment-title {
        font-size: 1rem;
    }
    
    .drs-carousel-comment-rating {
        font-size: 1rem;
    }

    .rating {
        font-size: 1.5rem;
    }
    
    .drs-carousel-comment-text {
        font-size: 1rem;
    }
    
    .drs-carousel-comment-date {
        font-size: 0.7rem;
    }
    .slick-prev,
    .slick-next {
        pointer-events: none;
    }

    .slick-prev:before {
        display: none;
        color: white;
    }

    .slick-next:before {
        color: white;
        display: none;
    }

    .slick-dots li.slick-active button:before {
        color: white;
    }

    .slick-dots > li > button {
        color: white;
    }

    .slick-dots > li > button:before {
        color: white;
    }
}