.drsqna {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    border-bottom: 1px solid white;
}

.drs-q {
    width: 95%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.drs-q > span{
    font-size: 24px;
    font-weight: 600;
    color: white;
}

.drs-a{
    font-size: 1.1rem;
    padding: 1rem 2rem 2rem 2rem;
    color:white;
}

@media (max-width: 66em) {
    .drs-q > span{
        font-size: 1.3rem;
    }
    
    .drs-a {
        font-size: 1rem;
    }
}

@media (max-width: 57em) {
    .drs-q > span{
        font-size: 1.1rem;
    }
    
    .drs-a {
        font-size: 1rem;
    }
}

@media (max-width: 35em) {
    .drsqna {
        width: 80vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        border-bottom: 1px solid white;
    }
    
    .drs-q {
        width: 95%;
    }
    
    .drs-q > span{
        font-size: 1rem;
        font-weight: 500;
    }
    
    .drs-a {
        font-size: 0.8rem;
        font-weight: 400;
    }
}