.change-title-price {
    padding: 0.5rem;
    color: black;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-weight: 500;
}

.change-title-price > label{
    font-size: 1.5rem;
}

.change-title-price > input{
    padding: 0.5rem;
    font-size: 1.3rem;
}

.change-title-price > button{
    padding: 0.5rem;
    font-size: 1.3rem;
    width: fit-content;
}

.change-title-price > h1{
    font-size: 4rem;
    letter-spacing: 3px;
    color: white;
}

.admin-package {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem;
    border: 1px solid gray;
}

.package-inputs {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.package-input {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.package-input > input{
    font-size: 1.2rem;
    padding: 0.2rem 0.5rem;
}

.package-input > button{
    width: fit-content;
    font-size: 1.1rem;
    padding: 0.2rem 0.5rem;
}

@media (max-width: 35em) {
    .change-title-price > h1{
        font-size: 2rem;
        text-align: center;
    }

    .change-title-price {
        width: 90%;
    }
    
    .change-title-price > label{
        font-size: 1.3rem;
    }
    
    .change-title-price > input{
        padding: 0.3rem;
        font-size: 1.2rem;
    }
    
    .change-title-price > button{
        padding: 0.5rem;
        font-size: 1.2rem;
        width: fit-content;
    }

    .admin-package {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .package-input > input{
        width: 100%;
        font-size: 1rem;
        padding: 0.2rem 0.5rem;
    }
    
    .package-input > button{
        width: fit-content;
        font-size: 1rem;
        padding: 0.2rem 0.5rem;
    }
}