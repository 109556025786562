.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact {
    padding: 5rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 2rem;
}

.lt {
    max-width: 40%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.lt > p {
    font-size: 3rem;
    font-weight: 500;
    color: white;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-name-email {
    width: 100%;
    display: flex;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group > label {
    color: white;
}

.form-group > input,
.form-group > textarea {
    border: none;
    border-bottom: 2px solid rgb(251, 188, 4);
    outline: none;
    background-color: transparent;
    padding: 0.5rem;
    color: white;
    font-size: 1.3rem;
    resize: none;
}

.contact-form-button {
    display: flex;
    justify-content: end;
    align-items: center;
}

.contact-form-submit {
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: black;
    font-weight: 500;
    font-size: 1.2rem;
    background-color: rgb(251, 188, 4);
    border: none;
    outline: none;
    border-radius: 5px;
}

.contact-form-submit:hover {
    background-color: rgb(192, 144, 2);
}

.disabled-button-contact {
    pointer-events: none;
    padding: 0.5rem 1rem;
    color: black;
    font-weight: 500;
    font-size: 1.2rem;
    background-color: lightgray;
    border: none;
    outline: none;
    border-radius: 5px;
}

.disclaimers {
    padding: 4rem 3rem 2rem 3rem;
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 1rem;
    color: white;
}

.disclaimer {
    max-width: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 0.8rem;
    padding-bottom: 2rem;
}

.disclaimer > span{
    font-size: 0.9rem;
}

.disclaimer > p{
    font-size: 0.8rem;
}

.gray-footer {
    width: 100%;
    background-color: rgb(224, 224, 224);
    color: rgb(23, 23, 23);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.75rem;
    padding: 1rem 0;
}

.footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    padding-left: 2rem;
}

.footer-links > a {
    text-decoration: none;
    color: rgb(23, 23, 23);
}

.footer-copyright {
    display: flex;
    gap: 1.5rem;
    padding-right: 2rem;
}

@media (max-width: 65em) {
    .contact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5rem;
    }
    
    .lt {
        max-width: 80%;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        text-align: center;
    }
    
    .lt > p {
        font-size: 2.5rem;
    }
}

@media (max-width: 35em) {
    .disclaimers {
        margin-bottom: 3rem;
        flex-direction: column;
        padding: 2rem 1rem;
    }
    
    .contact {
        display: flex;
        padding: 2rem 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }

    .lt {
        display: flex;
        flex-direction: column;
        max-width: 90%;
        gap: 1rem;
    }
    
    .lt > p{
        width: 100%;
        font-size: 2rem;
    }

    .contact-form {
        width: 85%;
    }
    
    .form-name-email {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    
    .form-group > label {
        color: white;
        font-size: 0.9rem;
    }
    
    .form-group > input,
    .form-group > textarea {
        font-size: 0.8rem;
    }
    
    .contact-form-button > button {
        font-size: 1rem;
    }
    
    .disclaimer {
        gap: 0.3rem;
    }
    
    .disclaimer > span{
        font-size: 0.7rem;
    }
    
    .disclaimer > p{
        font-size: 0.6rem;
    }

    .gray-footer {
        padding: 1rem 0.5rem;
        font-size: 0.5rem;
    }
    
    .footer-links {
        gap: 0.5rem;
    }
    
    .footer-copyright {
        display: flex;
        gap: 0.5rem;
    }
}