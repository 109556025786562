.delete-reviews {
    overflow: hidden;
    background-color: rgb(13, 79, 120);
}

.cookies {
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    gap: 1rem;
    top: 0;
    margin-left: 50%;
    transform: translate(-50%, 35vh);
    background-color: white;
    padding: 1rem 2rem;
    box-shadow: 0 0 1rem black;
    border-radius: 10px;
    animation: cookies 3s ease-in;
}

.cookie > h3 {
    font-size: 1.2rem;
    font-weight: 500;
}

.cookie > p {
    font-size: 1.1rem;
}

.cookie-buttons {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.cookie-buttons > button{
    cursor: pointer;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
}

.cookie-accept {
    background-color: rgb(251, 188, 4);
}

.cookie-buttons > button:hover{
    background-color: rgb(213, 160, 0);
}

.cookie-links {
    display: flex;
    gap: 1rem;
}

.cookie-links > a {
    color: rgb(251, 188, 4);
}

.contact-phone{
    background-color: rgb(49, 177, 49);
    width: fit-content;
    position: fixed;
    bottom: 4rem;
    right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 0.4rem 0.7rem;
    gap: 0.5rem;
    text-decoration: none;
}

.line {
    height: 0.5rem;
    background-color: white;
}

@keyframes cookies {
    0% {
        opacity: 0;
    }
    70% {
        opacity: 0;
        transform: translate(-50%,70vh);
    }
    100% {
        opacity: 1;
        transform: translate(-50%,35vh);
    }
}

@media (max-width: 35em) {
    .cookies {
        width: 60vw;
    }
    
    .cookie > h3 {
        font-size: 1rem;
    }
    
    .cookie > p {
        font-size: 0.9rem;
    }
    
    .cookie-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        width: 50vw;
    }
    
    .cookie-buttons > button{
        width: 100%;
        cursor: pointer;
        font-size: 1.1rem;
        padding: 0.5rem 1rem;
        border: none;
        outline: none;
    }
}