.admin-seven-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-seven-subtitle {
    padding: 0.5rem;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 500;
}

.admin-seven-subtitle > input{
    width: 92%;
    padding: 0.2rem 0.5rem;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
}

.admin-seven-subtitle > button{
    padding: 0.2rem 0.5rem;
    font-size: 1.2rem;
    width: fit-content;
    margin-bottom: 0.5rem;
}

.admin-seven-main-title {
    padding: 0.5rem;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.5rem;
}

.admin-seven-main-title > input{
    width: 98%;
    padding: 0.5rem;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
}

.admin-seven-main-title > button{
    padding: 0.5rem;
    font-size: 1.3rem;
    width: fit-content;
    margin-bottom: 0.5rem;
}

.add-new-qna {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.add-new-qna > button {
    font-size: 1.3rem;
    padding: 0.3rem 0.5rem;
}

.add-new-qna-inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.add-new-qna-inputs > input {
    width: 100%;
    font-size: 1.3rem;
    padding: 0.2rem;
}

.add-new-qna-buttons {
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-new-qna-buttons > button {
    font-size: 1.2rem;
    padding: 0.5rem;
}

@media (max-width:35em) {
    .admin-seven-subtitle {
        font-size: 1.2rem;
    }

    .admin-seven-subtitle > input{
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
    }
    
    .admin-seven-subtitle > button{
        font-size: 1.2rem;
    }
    
    .admin-seven-main-title {
        font-size: 1.2rem;
    }

    .admin-seven-main-title > h1{
        text-align: center;
    }
    
    .admin-seven-main-title > input{
        width: 89vw;
        font-size: 1.2rem;
    }
    
    .admin-seven-main-title > button{
        font-size: 1.2rem;
    }
}