.logout {
    position: fixed;
    border-bottom: 7px solid rgb(13, 79, 120);
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 1rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    z-index: 9999;
}

.logout > button {
    cursor: pointer;
    border: none;
    outline: none;
    background-color: rgb(251, 188, 4);
    font-size: 1.5rem;
    color: black;
    font-weight: 500;
    padding: 1rem 3rem;
    box-shadow: 0 0 0.3rem black;
    border-radius: 5px;
}

.logout > a {
    color: rgb(13, 79, 120);
    font-weight: 500;
    text-decoration: none;
    font-size: 1.5rem;
}

.logout > a:hover {
    text-decoration: underline;
}

.apply-button {
    cursor: pointer;
    background-color: rgb(251, 188, 4);
    border: none;
    outline: none;
}

.apply-button:hover{
    background-color: rgb(179, 152, 0);;
}

.change-color {
    float: right;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 0.5rem;
}

.change-color > label {
    color: white;
}

.change-color > input {
    cursor: pointer;
    background: none;
    border: none;
    width: 2.5rem;
    height: 2.5rem;
}

.change-color > button{
    padding: 0.2rem 0.4rem;
    font-size: 1rem;
}

@media (max-width: 35em) {
    .logout {
        padding: 1rem 0;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }

    .logout > button {
        font-size: 1.3rem;
        color: black;
        font-weight: 500;
        padding: 1rem 2rem;
        box-shadow: 0 0 0.3rem black;
        border-radius: 5px;
    }
    
    .logout > a {
        color: rgb(13, 79, 120);
        font-weight: 500;
        text-decoration: none;
        font-size: 1.3rem;
    }
}