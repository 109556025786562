.delete-reviews-section-four {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7rem 0;    
    background-color: rgb(13, 79, 120);
    color: white;
}

.pricing-table {
    background-color: rgb(13, 79, 120);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 3rem;
}

.pricing-table > h1 {
    font-family: "Bitter Bold", sans-serif;
    font-size: 4rem;
    letter-spacing: 3px;
    color: white;
}

.packages {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.package {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 18rem;
    padding: 1.5rem;
    border-radius: 10px;
}

.package > h2 {
    font-size: 2rem;
    color: black;
    text-align: center;
}

.package > span {
    font-size: 1.1rem;
    font-weight: normal;
    color: gray;
}

.price {
    font-size: 4rem;
    color: black;
    font-weight: bold;
}

.old-price {
    font-size: 1.2rem;
    color: black;
    text-decoration: line-through;
}

.savings {
    font-size: 1.4rem;
    color: black;
    margin-bottom: 10px;
}

.description {
    font-size: 0.9rem;
    color: black;
    text-align: center;
}

.bulk-discount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #ddd;
}

.bulk-discount > p {
    font-size: 1.2rem;
    color: #333;
}

.bulk-discount > a {
    cursor: pointer;
    text-decoration: none;
    color: black;
    font-weight: bold;
    background-color: rgb(251, 188, 4);
    padding: 1rem 2.5rem;
    font-size: 1.2rem;
    border-radius: 5px;
}

.bulk-discount > a:hover {
    background-color: rgb(179, 152, 0);
}

@media (max-width: 70em) {
    .package {
        width: 16rem;
        padding: 1.4rem;
    }
    
    .package > h2 {
        font-size: 1.9rem;
    }
    
    .package > span {
        font-size: 1rem;
    }
    
    .price {
        font-size: 4rem;
    }
    
    .old-price {
        font-size: 1.1rem;
    }
    
    .savings {
        font-size: 1.3rem;
    }
    
    .description {
        font-size: 0.9rem;
    }
}

@media (max-width: 62em) {
    .package {
        width: 14rem;
        padding: 1.3rem;
    }
    
    .package > h2 {
        font-size: 1.7rem;
    }
    
    .package > span {
        font-size: 1rem;
    }
    
    .price {
        font-size: 3.8rem;
    }
    
    .old-price {
        font-size: 1rem;
    }
    
    .savings {
        font-size: 1.2rem;
    }
    
    .description {
        font-size: 0.8rem;
    }

    .bulk-discount > p {
        font-size: 1.1rem;
    }
    
    .bulk-discount > a {
        font-size: 1.1rem;
        border-radius: 5px;
    }
}

@media (max-width: 55em) {
    .pricing-table > h1 {
        font-size: 3.5rem;
    }

    .package {
        width: 13rem;
        padding: 1.1rem;
    }
    
    .package > h2 {
        font-size: 1.5rem;
    }
    
    .package > span {
        font-size: 0.9rem;
    }
    
    .price {
        font-size: 3.5rem;
    }
    
    .old-price {
        font-size: 0.9rem;
    }
    
    .savings {
        font-size: 1rem;
    }
    
    .description {
        font-size: 0.8rem;
    }

    .bulk-discount > p {
        font-size: 1rem;
    }
    
    .bulk-discount > a {
        font-size: 1rem;
        border-radius: 5px;
    }
}

@media (max-width: 50em) {
    .pricing-table > h1 {
        font-size: 2.5rem;
    }

    .package {
        width: 10rem;
        padding: 1rem;
    }
    
    .package > h2 {
        font-size: 1.1rem;
    }
    
    .package > span {
        font-size: 0.7rem;
    }
    
    .price {
        font-size: 2.8rem;
    }
    
    .old-price {
        font-size: 0.7rem;
    }
    
    .savings {
        font-size: 0.8rem;
    }
    
    .description {
        font-size: 0.5rem;
    }

    .bulk-discount > p {
        font-size: 0.8rem;
    }
    
    .bulk-discount > a {
        font-size: 0.8rem;
        border-radius: 5px;
    }
}

@media (max-width: 39em) {
    .pricing-table > h1 {
        letter-spacing: 0;
        font-size: 2rem;
        text-align: center;
        width: auto;
    }
    
    .packages {
        flex-direction: column;
    }

    .package {
        width: 15rem;
        padding: 1rem;
    }
    
    .package > h2 {
        font-size: 1.7rem;
    }
    
    .package > span {
        font-size: 0.9rem;
    }
    
    .old-price {
        font-size: 1.1rem;
    }
    
    .savings {
        font-size: 1.3rem;
    }
    
    .description {
        font-size: 0.8rem;
    }

    .bulk-discount > p {
        text-align: center;
    }
}