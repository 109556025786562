.change-title-wrap > h1 {
    text-align: center;
}

.change-text-about-us {
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
    padding: 0.5rem;
    gap: 0.5rem;
    width: 90%;
}

.admin-about-us-text {
    text-align: center;
    font-family: "Bitter Regular", sans-serif;
    font-size: 1.5rem;
    padding: 0 2rem;
    width: auto;
    text-align: center;
}

.change-text-about-us > label{
    font-size: 1.3rem;
    color: black;
    font-weight: 500;
}

.change-text-about-us > textarea{
    font-size: 1.3rem;
    padding: 0.2rem 0.5rem;
}

.change-text-about-us > button{
    width: fit-content;
    font-weight: 500;
    padding: 0.5rem;
    font-size: 1.3rem;
}

@media (max-width: 45em) {
    .admin-about-us-text {
        width: auto;
        font-size: 1.3rem;
        padding: 0 0.5rem;
    }
    
    .change-text-about-us > label{
        font-size: 1.3rem;
        color: black;
        font-weight: 500;
    }
    
    .change-text-about-us > textarea{
        font-size: 1.2rem;
        padding: 0.2rem 0.5rem;
    }
    
    .change-text-about-us > button{
        width: fit-content;
        font-weight: 500;
        padding: 0.5rem;
        font-size: 1.2rem;
    }    
}