.delete-reviews-section-one {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 2rem;
    align-items: start;
    padding: 10rem 2rem 5rem 2rem;
    background-color: rgb(13, 79, 120);
    color: white;
}

.drs-one-left {
    /* max-width: 50rem; */
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.drs-one-left > h1 {
    color: white;
    font-family: "Bitter Bold", sans-serif;
    font-size: 4rem;
}

.drs-one-left > ul {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 1.5rem;
}

.drs-one-left > ul > li {
    font-size: 22px;
    font-weight: 400;
    color: white;
}

.drs-one-right {
    max-width: 50rem;
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.drs-one-right-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 0.5rem;
}

.individual-form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.individual-form > input,
.drs-one-right-form > input {
    margin-top: 5px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
}

.drs-one-right-form > select {
    width: calc(100% + 20px);
    margin-top: 5px;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    border: none;
    color: black;
    background-color: white;
}

.add-individual {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10000;
}

.ind-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    max-height: 20rem;
    overflow: auto;
    padding: 0.5rem;
    gap: 0.5rem;
    box-sizing: border-box;
}

.inds {
    width: calc(100% + 1rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    border: 1px solid white;
    border-radius: 10px;
    box-sizing: border-box;
}

.inds-ind {
    width: calc(90% + 1rem);
    padding: 0.5rem;
    box-sizing: border-box;
}

.inds > span{
    padding: 0.5rem;
    box-sizing: border-box;
}
.inds > p{
    padding: 0rem 0.5rem 0.5rem 0.5rem;
    box-sizing: border-box;
}

.inds-ind > span,
.inds-ind > p{
    padding: 0rem;
    box-sizing: border-box;
}

.ifb {
    color: black;
}

.dropdown-reason{
    width: 100%;
    position: relative;
}

.dropdown-select {
    width: calc(100% + 8px);
    border-radius: 5px;
    position: relative;
    margin-top: 4px;
    padding: 10px 2px 10px 10px;
    background-color: white;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown-box {
    z-index: 99;
    background-color: white;
    position: absolute;
    color: black;
    width: calc(100% + 20px);
}

.dropdown-box > ul {
    list-style-type: none;
    width: 100%;
}

.dropdown-box > ul > li{
    padding: 0.1rem 0.5rem;
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 0.7rem;
}

.dropdown-box > ul > li:hover{
    background-color: rgb(8, 104, 195);
    color: white;
}

.dropdown-reason-mob {
    width: 95%;
}

.dropdown-box-mob {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
}

.dropdown-mob-list {
    background-color: white;
    width: 90%;
    border-radius: 20px;
    border: 1px solid black;
    color: black;
}

.dropdown-mob-list > li {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 1.2rem;
    padding: 0.5rem;
    border-bottom: 1px solid lightgray;
}

.dd-info {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dd-desc {
    width: 18rem;
    background-color: rgb(65, 65, 65);
    color: white;
    z-index: 101;
    position: absolute;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.3rem 0.7rem;
    top: 1.5rem;
    left: -1rem;
    border-radius: 5px;
}

.dd-desc::before {
    position: absolute;
    top: -1rem;
    left: 1rem;
    content: "";
    border-bottom: 10px solid rgb(65, 65, 65);
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

.dd-select {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
}

.drs-one-right-form > textarea{
    resize: none;
    margin-top: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    font-family: sans-serif;
    width: 100%;
}

.checkbox-link {
    text-decoration: none;
    color: rgb(0, 170, 255);
}

.checkbox-form {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.7rem;
    padding-left: 0.3rem;
}

.checkbox-custom::after {
    content: '';
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    line-height: 1em;
    margin-top: -4px;
    margin-left: -4px;
    border: 1px solid grey;
    border-radius: 0;
    background-color: white;
}
  
.checkbox-custom:checked::after {
    content:'\2713';
    font-size: 1.2rem;
    width: 1.2rem;
    height: 1.2rem;
    color: green;
    text-align:center;
}

.message-sent {
    width: calc(100%-1rem);
    background-color: #4BB543;
    padding: 0.5rem;
}

.send-button {
    cursor: pointer;
    margin-top: 20px;
    padding: 15px;
    font-weight: bold;
    color: black;
    font-size: 1.2rem;
    border-radius: 5px;
    border: none;
    width: fit-content;
    background-color: rgb(251, 188, 4);
}

.send-button:hover {
    background-color: rgb(200, 170, 0);
}

.disabled-button {
    cursor: not-allowed;
    pointer-events: none;
    margin-top: 20px;
    padding: 15px;
    font-weight: bold;
    color: black;
    font-size: 1.2rem;
    border-radius: 5px;
    border: none;
    width: fit-content;
    background-color: lightgray;
}

.disabled-button-next {
    background-color: lightgray;
    pointer-events: none;
    border: none;
    outline: none;
}

@media (max-width: 80em) {
    .drs-one-left > h1 {
        font-size: 3.5rem;
    }
    .drs-one-left > ul {
        padding: 0 2rem;
    }
    
    .drs-one-left > ul > li {
        font-size: 1.3rem;
        line-height: 1.4rem;
        margin-bottom: 1rem;
    }
}

@media (max-width: 55em) {
    .drs-one-left > h1 {
        font-size: 3rem;
    }
    .drs-one-left > ul {
        padding: 0 1rem;
    }
    
    .drs-one-left > ul > li {
        font-size: 1.2rem;
        line-height: 1.3rem;
        margin-bottom: 0.9rem;
    }


}

@media (max-width: 45em) {
    .delete-reviews-section-one {
        padding: 7rem 2rem 5rem 2rem;
        flex-direction: column;
        gap: 3rem;
    }

    .drs-one-left {
        width: auto;
        gap: 1rem;
        align-items: center;
    }
    
    .drs-one-left > h1 {
        font-size: 3rem;
        font-weight: 400;
        letter-spacing: 0;
        word-spacing: 0;
        text-align: center;
    }
    
    .drs-one-left > ul {
        padding: 1rem 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        gap: 1rem;
    }
    
    .drs-one-left > ul > li {
        font-size: 1.1rem;
        font-weight: 400;
    }
    
    .drs-one-left > a {
        padding: 0.7rem 1.5rem;
    }

    .drs-one-right {
        width: 100%;
    }

    .individual-form > input,
    .drs-one-right-form > input {
        width: 95%;
    }

    .drs-one-right-form > select {
        width: calc(95% + 20px);
        padding: 10px;
    }

    .drs-one-right-form > textarea{
        width: 95%;
    }

    .dropdown-select {
        width: calc(95% + 8px);
    }

    .inds {
        width: calc(100% - 1rem);
    }

    .ind-form-wrap {
        width: 60vw;
    }

    .ifb {
        padding: 0;
        font-size: 0.8rem;
        font-weight: 500;
    }

    .dropdown-reason-mob {
        width: 100%;
    }
}
