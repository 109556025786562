.delete-reviews-section-two {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5rem;
}

.delete-reviews-section-two > h1 {
    font-family: "Bitter Bold", sans-serif;
    font-size: 4rem;
    width: auto;
    padding: 0 0.5rem;
}

.delete-reviews-section-two > p {
    font-family: "Bitter Regular", sans-serif;
    font-size: 1.5rem;
    padding: 0 2rem;
    width: auto;
    text-align: center;
}
        
.delete-reviews-section-two{
    padding: 7rem 0;
    background-color: rgb(13, 79, 120);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: white;
    font-weight: bold;
}

@media (max-width: 45em) {
    .delete-reviews-section-two {
        background-image: none;
        background-color: rgb(13, 79, 120);
    }

    .delete-reviews-section-two > p {
        font-size: 1rem;
        width: auto;
        padding: 0 0.5rem;
    }
}