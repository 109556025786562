.page-number {
    position: absolute;
    left: 2%;
    top: 2%;
    padding: 0.5rem;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    color: white;
    width: fit-content;
}

.page-number-inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.page-number-inputs > input{
    width: 65%;
    padding: 0.2rem 0.4rem;
}

.page-number-inputs > button{
    padding: 0.3rem 0.5rem;
}

.admin-drs-one-left {
    width: 50%;
}

.admin-drs-one-left > ul {
    padding: 1rem;
}

.change-title-wrap {
    padding: 0.5rem;
    border: 1px gray solid;
}

.change-title-wrap > h1 {
    color: white;
    font-family: "Bitter Bold", sans-serif;
    font-size: 4rem;
}

.change-font {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border: 1px dotted black;
    width: fit-content;
    margin-inline: auto;
    padding: 0.5rem;
}

.change-font-family {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.change-font-family > select {
    font-size: 1.1rem;
    padding: 0.2rem 0.5rem;
    border: none;
    outline: none;
}

.change-font-family > button{
    font-size: 1.1rem;
    padding: 0.2rem 0.5rem;
    border: none;
    outline: none;
}

.change-font-size {
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 0.3rem;
    font-weight: 400;
}

.change-font-size > span{
    font-size: 0.8rem;
    color: white;
}
.change-font-size > span > p{
    text-transform: capitalize;
    font-size: 1.2rem;
    color: white;
}

.change-font-size > input {
    padding: 0.2rem 0.5rem;
    font-size: 1.1rem;
    border: none;
    outline: none;
    width: fit-content;
}

.change-font-size > button {
    padding: 0.2rem 0.5rem;
    font-size: 1.1rem;
}

.change-title {
    gap: 0.5rem;
    color: black;
    font-weight: 500;
    display: flex;
    flex-direction: column;
}

.change-title > label{
    font-size: 1.5rem;
}

.change-title > input{
    padding: 0.5rem;
    font-size: 1.3rem;
}

.change-title > button{
    padding: 0.5rem;
    font-size: 1.3rem;
    width: fit-content;
}

.change-text-list {
    border: 1px solid gray;
    padding: 0.5rem;
    margin-top: 0.5rem;
    gap: 0.5rem;
    color: black;
    font-weight: 500;
    display: flex;
    flex-direction: column;
}

.change-text-list > li {
    font-size: 22px;
    font-weight: 400;
    color: white;
}

.change-text-list > label{
    font-size: 1.3rem;
}

.change-text-list > input{
    font-size: 1.2rem;
    padding: 0.2rem 0.5rem;
}

.change-text-list > button{
    width: fit-content;
    font-size: 1.2rem;
    padding: 0.2rem 0.5rem;
}

.apply-button-placeholder {
    display: flex;
    justify-content: end;
    align-items: center;
}

.apply-button-placeholder > button {
    font-size: 1.2rem;
    padding: 0.3rem 0.5rem;
}

.individual-form-admin {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10000;
}

.ind-form-wrap {
    position: relative;
    width: 35rem;
    border-radius: 20px;
    box-shadow: 0 0 0.5rem lightgray;
    background-color: rgb(13, 79, 120);
    padding: 4rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.close-individual-form {
    position: absolute;
    top: 5%;
    right: 3%;
    font-size: 2rem;
    font-weight: 500;
    background: none;
    cursor: pointer;
    border: none;
    outline: none;
    color: white;
}

.ind-form-wrap > input {
    padding: 0.2rem 0.5rem;
    font-size: 1.3rem;
    width: 100%;
}

.ind-next-button {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.ind-next-button > button {
    font-size: 1.3rem;
    font-weight: 500;
    padding: 0.3rem 0.6rem;
}

.individual-form-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.individual-form-buttons > div {
    font-size: 1.3rem;
    font-weight: 500;
    padding: 0.3rem 0.6rem;
    color: black;
}

.change-input-message {
    padding: 0.5rem;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.successfuly-send {
    border: 1px solid gray;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.change-message-successfuly {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: black;
    font-weight: 500;
    font-size: 1.2rem;
}

.change-message-successfuly > input{
    padding: 0.2rem 0.5rem;
    font-size: 1.2rem;
}

.change-message-successfuly > button{
    width: fit-content;
    padding: 0.5rem;
    font-size: 1.2rem;
}

@media (max-width: 60em) {
    .change-font {
        flex-direction: column;
    }
}

@media (max-width: 45em) {
    .page-number {
        left: 2%;
        top: 10px;
    }

    .admin-drs-one-left {
        width: 100%;
        margin-top: 10rem;
    }

    .change-title-wrap > h1 {
        font-size: 2.2rem;
    }

    .change-text-list > li {
        font-size: 1rem;
    }

    .change-font {
        flex-direction: column;
    }
}