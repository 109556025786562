.delete-reviews-section-seven {
    position: relative;
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background-color: rgb(13, 79, 120);
    color: white;
}

.drs-seven-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Bitter Bold", sans-serif;
}

.drs-seven-title > span {
    font-weight: 400;
    letter-spacing: 2px;
    color: white;
}

.drs-seven-title > h1 {
    width: 80%;
    margin-top: 1rem;
    color: white;
    font-size: 3rem;
    font-weight: 500;
    text-align: center;
}

.drs-seven-qna {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 65em) {   
    .drs-seven-title > h1 {
        font-size: 2.5rem;
    }
}
@media (max-width: 55em) {   
    .drs-seven-title > h1 {
        font-size: 2.25rem;
    }
}

@media (max-width: 45em) {   
    .drs-seven-title > h1 {
        font-size: 2rem;
    }
}

@media (max-width: 35em) {
    .drs-seven-title {
        width: auto;
    }

    .drs-seven-title > span {
        font-weight: 400;
        letter-spacing: 2px;
    }
    
    .drs-seven-title > h1 {
        font-size: 2.2rem;
        font-weight: 400;
        text-align: center;
    }
}