.admin-drs-eight-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border: 1px solid gray;
}

.admin-drs-eight-title > label {
    font-size: 1.5rem;
    color: black;
    font-weight: 500;
}

.admin-drs-eight-title > input {
    width: calc(100% - 1.2rem);
    padding: 0.5rem;
    font-size: 1.3rem;
}

.admin-drs-eight-title > button {
    width: fit-content;
    font-size: 1.2rem;
    padding: 0.3rem 0.5rem;
}

.admin-drs-eight-title > h1 {
    text-align: center;
    font-size: 4rem;
    color: white;
}

.admin-box-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.admin-box-eight > label {
    color: black;
}

.box-inputs {
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 0.5rem;
}

.box-inputs > input,
.box-inputs > textarea {
    width: 90%;
    padding: 0.3rem 0.5rem;
    font-size: 1.2rem;
    resize: none;
}

.box-inputs > button{
    padding: 0.3rem 0.5rem;
    font-size: 1.2rem;
}

.admin-drs-eight-comments-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border: 1px solid gray;
}

.admin-drs-eight-comments-title > label {
    font-size: 1.5rem;
    color: black;
    font-weight: 500;
}

.admin-drs-eight-comments-title > input {
    width: calc(100% - 1.2rem);
    padding: 0.5rem;
    font-size: 1.3rem;
}

.admin-drs-eight-comments-title > button {
    width: fit-content;
    font-size: 1.2rem;
    padding: 0.3rem 0.5rem;
}

.admin-drs-eight-comments-title > h3 {
    text-align: center;
    font-size: 2.5rem;
    color: white;
}

.admin-drs8-comment {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    border: 1px solid gray;
}

.admin-drs8-comment-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.admin-req {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid black;
    gap: 0.5rem;
}

.admin-req > textarea{
    width: 70%;
    resize: none;
    padding: 0.5rem;
    font-size: 1.3rem;
}

.apply-button-req{
    cursor: pointer;
    background-color: rgb(13, 79, 120);
    border: none;
    outline: none;
    color: white;
    font-weight: 500;
    padding: 0.5rem 0.7rem;
    font-size: 1.3rem;
}

.apply-button-req:hover {
    background-color: rgb(8, 61, 94);
}

.admin-req > p {
    width: 90%;
    font-size: 2rem;
    text-align: center;
}

@media (max-width: 35em) {
    .admin-drs-eight-title > label {
        font-size: 1.3rem;
    }
    
    .admin-drs-eight-title > input {
        font-size: 1.3rem;
    }
    
    .admin-drs-eight-title > button {
        font-size: 1.3rem;
    }
    
    .admin-drs-eight-title > h1 {
        width: auto;
        text-align: center;
        font-size: 1.7rem;
    }

    .box-inputs > input,
    .box-inputs > textarea {
        width: 90%;
        padding: 0.2rem 0.4rem;
        font-size: 0.9rem;
        resize: none;
    }

    .box-inputs > button{
        padding: 0.2rem 0.4rem;
        font-size: 0.9rem;
    }

    .admin-req > textarea{
        width: 70%;
        font-size: 1rem;
    }

    .admin-req > button{
        font-size: 1rem;
    }

    .admin-req > p {
        width: auto;
        font-size: 1.2rem;
        text-align: center;
    }
}