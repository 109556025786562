.change-title-sequence {
    padding: 0.5rem;
    color: black;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-weight: 500;
}

.change-title-sequence > label{
    font-size: 1.5rem;
}

.change-title-sequence > input{
    padding: 0.5rem;
    font-size: 1.3rem;
}

.change-title-sequence > button{
    padding: 0.5rem;
    font-size: 1.3rem;
    width: fit-content;
}

.change-title-sequence > h1{
    font-size: 3rem;
    color: white;
    text-align: center;
}

.afcc {
    font-size: 0.9rem;
    font-weight: normal;
}

.admin-step {
    padding: 1rem;
    border: 1px solid gray;
}

.admin-step-top > span {
    margin-top: 1rem;
}

.admin-step-top > input{
    margin: 0.5rem 0;
    width: 90%;
    padding: 0.2rem 0.5rem;
    font-size: 1.1rem;
}

.admin-step-top > button{
    padding: 0.2rem 0.5rem;
    font-size: 1.2rem;
    width: fit-content;
}

.admin-step > textarea{
    margin: 0.5rem 0;
    width: 90%;
    padding: 0.2rem 0.5rem;
    font-size: 1.1rem;
    resize: none;
}

.admin-step > button{
    padding: 0.2rem 0.5rem;
    font-size: 1.2rem;
    width: fit-content;
}

@media (max-width:35em) {
    .change-title-sequence > h1{
        font-size: 2.2rem;
    }
}