.delete-reviews-section-eight {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 7rem 0 0 0;
    background-color: rgb(13, 79, 120);
}

.delete-reviews-section-eight > h1 {
    text-align: center;
    font-family: "Bitter Bold", sans-serif;
    font-size: 4rem;
    color: white;
}

.delete-reviews-section-eight-boxes {
    max-width: 100rem;
    padding: 5rem 2rem;
    gap: 3rem;
    display: grid;
    grid-template-columns: repeat(2, auto);
}

.box {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 1rem;
    min-height: 7rem;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 1rem rgb(55, 55, 55);
}

.box-icon > svg {
    width: 5rem;
}

.box-text > h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.box-text > p {
    font-size: 1.1rem;
}

.req {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: rgb(251, 188, 4);
}

.req > p {
    padding: 2rem 2rem;
    font-size: 2rem;
    width: 70%;
    text-align: center;
}

@media (max-width: 69em) {
    .delete-reviews-section-eight > h1 {
        font-size: 3.5rem;
    }
}

@media (max-width: 60em) {
    .delete-reviews-section-eight > h1 {
        font-size: 3rem;
    }

    .box-text > h3 {
        font-size: 1.3rem;
        margin-bottom: 0.5rem;
    }
    
    .box-text > p {
        font-size: 1rem;
    }
}

@media (max-width: 51em) {
    .delete-reviews-section-eight > h1 {
        font-size: 2.5rem;
    }

    .box-text > h3 {
        font-size: 1.1rem;
        margin-bottom: 0.5rem;
    }
    
    .box-text > p {
        font-size: 0.9rem;
    }

    .req > p {
        font-size: 1.5rem;
    }
    
}

@media (max-width: 44em) {
    .delete-reviews-section-eight > h1 {
        text-align: center;
        font-size: 5vw;
    }

    .delete-reviews-section-eight-boxes {
        max-width: 100rem;
        padding: 3rem 1rem;
        gap: 1.5rem;
        grid-template-columns: repeat(1, auto);
    }

    .box-text > h3 {
        font-size: 1.3rem;
        margin-bottom: 0.5rem;
    }
    
    .box-text > p {
        font-size: 1rem;
    }

    .delete-reviews-section-eight > h3 {
        padding: 0 1rem;
        text-align: center;
        font-size: 1.8rem;
        margin-top: 5rem;
    }
    
    .drs8-comments {
        flex-direction: column;
        padding: 2rem 1rem;
        gap: 3rem;
    }

    .drs8-comment > h3 {
        font-size: 1.35rem;
    }
    
    .drs8-comment > p {
        font-size: 1rem;
        color: (202, 202, 202)
    }
    
    .drs8-customer {
        margin-top: 2rem;
        font-size: 0.9rem;
    }

    .req {
        margin-top: 3rem;
        width: 100%;
    }
    
    .req > p {
        padding: 1rem 2rem;
        font-size: 1.2rem;
        width: auto;
        text-align: center;
    }
}