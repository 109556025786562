.admin-section-six-title {
    padding: 0.5rem;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
}

.admin-section-six-title > label {
    color: black;
    font-weight: 500;
    font-size: 1.3rem;
}

.admin-section-six-title > input {
    width: calc(100% - 1.2rem);
    padding: 0.5rem;
    font-size: 1.2rem;
}

.admin-section-six-title > button {
    width: fit-content;
    padding: 0.3rem 0.5rem;
    font-size: 1.2rem;
}

.admin-section-six-title > h3 {
    font-size: 1.3rem;
    text-align: center;
}
