.delete-reviews-section-five {
    position: relative;
    padding: 10rem 0;
    display: flex;
    flex-direction: column;;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    background-color: rgb(13, 79, 120);
    color: white;
}

.delete-reviews-section-five > h1 {
    color: white;
    font-family: "Bitter Bold", sans-serif;
    font-size: 3.5rem;
    text-align: center;
}

.drs-five-steps {
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 5rem;
}

.drs-five-step {
    width: 15rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.drs-five-step > p {
    color: white;
    font-size: 1.2rem;
}

.drs-five-step-top {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    color: rgb(0, 26, 48);
    font-weight: bold;
}

.drs-five-step-top > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    color: white;
}

.drs-five-step-top > p {
    font-size: 1.7rem;
    color: white;
}

.step-num {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(251, 188, 4);
    border-radius: 100%;
    padding: 0.7rem;
    width: 1rem;
    height: 1rem;
    color: black;
}

@media (max-width: 63em) {
    .delete-reviews-section-five > h1 {
        font-size: 3rem;
    }
    
    .drs-five-steps {
        gap: 4rem;
    }
    
    .drs-five-step {
        width: 13rem;
        gap: 0.5rem;
    }
    
    .drs-five-step > p {
        font-size: 1.1rem;
    }
    
    .drs-five-step-top > span {
        font-size: 1.1rem;
    }
    
    .drs-five-step-top > p {
        font-size: 1.6rem;
    }
    
    .step-num {
        padding: 0.7rem;
        width: 1rem;
        height: 1rem;
    }
}
@media (max-width: 52em) {
    .delete-reviews-section-five > h1 {
        font-size: 2.6rem;
    }
    
    .drs-five-steps {
        gap: 2rem;
    }
    
    .drs-five-step {
        width: 12rem;
        gap: 0.5rem;
    }
    
    .drs-five-step > p {
        font-size: 1rem;
    }
    
    .drs-five-step-top > span {
        font-size: 1rem;
    }
    
    .drs-five-step-top > p {
        font-size: 1.5rem;
    }
    
    .step-num {
        padding: 0.7rem;
        width: 1rem;
        height: 1rem;
    }
}

@media (max-width: 45em) {
    .delete-reviews-section-five > h1 {
        font-size: 2rem;
    }
    
    .drs-five-steps {
        gap: 1.5rem;
    }
    
    .drs-five-step {
        width: 10rem;
        gap: 0.5rem;
    }
    
    .drs-five-step > p {
        font-size: 0.9rem;
    }
    
    .drs-five-step-top > span {
        font-size: 0.9rem;
    }
    
    .drs-five-step-top > p {
        font-size: 1.3rem;
    }
    
    .step-num {
        padding: 0.7rem;
        width: 1rem;
        height: 1rem;
    }
}

@media (max-width: 35em) {
    .delete-reviews-section-five > h1 {
        font-size: 2.2rem;
        text-align: center;
        padding: 0 1rem;
    }

    .drs-five-steps {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }

    .drs-five-step {
        width: 70vw;
    }
}