.admin-footer {
    background-color: rgb(13, 79, 120);
}

.contact-info {
    max-width: 70%;
}

.change-footer-texts > p{
    text-align: center;
}

.change-footer-text {
    padding: 0.5rem;
    border: 1px solid gray;
    color: white;
}

.change-text {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.change-text > label {
    font-size: 1.5rem;
}

.change-text > input{
    padding: 0.3rem 0.5rem;
    font-size: 1.3rem;
}

.change-text > button {
    width: fit-content;
    padding: 0.5rem;
    font-size: 1.2rem;
}

.admin-footer-title > h3 {
    font-size: 3.5rem;
}

.change-info {
    padding: 0.5rem;
    border: 1px solid gray;
}

.change-info > label {
    color: white;
}