.delete-reviews-section-six {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 7rem 2rem;
    gap: 5rem;
    background-color: rgb(13, 79, 120);
    color: white;
}

.delete-reviews-section-six > h3 {
    font-family: "Bitter Regular", sans-serif;
    font-size: 3rem;
    text-align: center;
}

.drs-six-comments {
    width: 70%;
    max-width: 80rem;
}

@media (max-width: 35em) {
    .delete-reviews-section-six {
        flex-direction: column;
        align-items: center;
        padding: 5rem 2rem 10rem 2rem;
        gap: 5rem;
    }

    .drs-six-comments {
        width: 100%;
    }
}
