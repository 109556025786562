.datenschutz {
    background-color: rgb(13, 79, 120);
    color: white;
}

.datenschutz > h1 {
    padding: 10rem 0 5rem 0;
    text-align: center;
    color: rgb(251, 188, 4);
    font-family: "Bitter Bold", sans-serif;
    font-size: 56px;
    letter-spacing: 10px;
    line-height: 56px;
    font-weight: 500;
}

.datenschutz-text {
    padding: 2rem 7rem;
}

.datenschutz-text > h2{
    font-size: 1.7rem;
}

.datenschutz-text > h3{
    margin-top: 1.2rem;
}

.datenschutz-text > p{
    margin-top: 1rem;
}

.datenschutz-text > ul{
    padding: 1rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.datenschutz-link {
    color: rgb(251, 188, 4);
    text-decoration: none;
}

.datenschutz-link:hover {
    color: rgb(211, 158, 0);
}

@media (max-width: 35em) {

    .datenschutz-logo-img {
        padding-top: 2rem;
        width: 15rem;
    }

    .datenschutz > h1 {
        font-size: 1.3rem;
        letter-spacing: 2px;
        padding: 6rem 0 0 0;
    }
    
    .datenschutz-text {
        padding: 0 0.5rem;
    }
    
    .datenschutz-text > h2{
        font-size: 1.3rem;
        margin-top: 4rem;
    }
    
    .datenschutz-text > h3{
        margin-top: 1.1rem;
    }
    
    .datenschutz-text > p{
        margin-top: 0.5rem;
    }
    
    .datenschutz-text > ul{
        padding: 1rem 2rem;
    }
}