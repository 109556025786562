.delete-reviews-section-three {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    padding: 7rem 0 0 0;
    background-color: rgb(13, 79, 120);
    color: white;
}

.drs-three-title {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.drs-three-title > h1 {
    font-family: "Bitter Bold", sans-serif;
    font-size: 2.5rem;
    color: white;
}

.drs-three-title > p {
    font-size: 1.1rem;
    padding-bottom: 2rem;
    color: white;
}

.drs-three-img > img{
    width: 20rem;
    transform: translateY(20%);
}

@media (max-width: 65em) {
    .drs-three-img > img{
        width: 17rem;
        transform: translateY(20%);
    }
    .drs-three-title > h1 {
        font-family: "Bitter Bold", sans-serif;
        font-size: 2.2rem;
        color: white;
    }
    
    .drs-three-title > p {
        font-size: 1rem;
        color: white;
    }
}

@media (max-width: 57em) {
    .drs-three-img > img{
        width: 15rem;
        transform: translateY(20%);
    }
    .drs-three-title > h1 {
        font-family: "Bitter Bold", sans-serif;
        font-size: 2rem;
        color: white;
    }
    
    .drs-three-title > p {
        font-size: 1rem;
        color: white;
    }
}

@media (max-width: 52em) {
    .drs-three-img > img{
        width: 15rem;
        transform: translateY(20%);
    }
    .drs-three-title > h1 {
        font-family: "Bitter Bold", sans-serif;
        font-size: 1.8rem;
        color: white;
    }
    
    .drs-three-title > p {
        font-size: 0.9rem;
        color: white;
    }
}

@media (max-width: 40em) {
    .delete-reviews-section-three {
        flex-direction: column;
    }

    .drs-three-title {
        width: auto;
        padding: 2rem 3rem 0 3rem;
        gap: 2rem;
    }
    
    .drs-three-title > h1 {
        font-size: 2rem;
        text-align: center;
    }
    
    .drs-three-title > p {
        font-size: 1rem;
    }
}