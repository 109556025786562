.add-new-review {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.add-new-comment-form {
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.7);
    position: absolute;
    padding: 5rem 1rem;
    width: 50rem;
    z-index: 100;
    background-color: black;
}

.add-new-comment-form > form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.add-new-comment-form > form > input,
.add-new-comment-form > form > textarea{
    resize: none;
    font-size: 1.5rem;
    padding: 0.5rem;
    width: 70%;
}

.add-new-comment-form > form > button{
    resize: none;
    font-size: 1.5rem;
    padding: 0.5rem;
    width: fit-content;
}

.close-button {
    position: absolute;
    top: 3%;
    right: 4%;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    color: white;
    font-size: 2rem;
    font-weight: 500;
}

.delete-review-carousel {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.add-new-review > button{
    padding: 1rem 2rem;
    font-size: 1.5rem;
}

.delete-button {
    cursor: pointer;
    background-color: black;
    color: white;
    padding: 0.2rem;
    border: none;
    outline: none;
}

.admin-drs-carousel-comment {
    padding: 0.5rem;
    border: 1px solid gray;
    margin-left: -1%;
}

.admin-comment {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
}

.admin-comment-input {
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 0.5rem;
    width: 100%;
}

.admin-comment-input > input {
    width: 70%;
    padding: 0.5rem;
    font-size: 1.3rem;
}

.admin-comment-input > textarea {
    width: 70%;
    padding: 0.5rem;
    font-size: 1.3rem;
    resize: none;
}

.admin-comment-input > button {
    color: black;
    font-weight: 500;
    padding: 0.3rem 0.5rem;
    font-size: 1.3rem;
}

@media (max-width:35em) {  
    .admin-comment-input {
        display: flex;
        justify-content: center;
        align-items: end;
        gap: 0.5rem;
        width: 100%;
    }
    
    .admin-comment-input > input {
        width: 70%;
        padding: 0.2rem 0.4rem;
        font-size: 1rem;
    }
    
    .admin-comment-input > textarea {
        width: 70%;
        padding: 0.2rem 0.4rem;
        font-size: 1rem;
        resize: none;
    }
    
    .admin-comment-input > button {
        color: black;
        font-weight: 500;
        padding: 0.2rem 0.4rem;
        font-size: 1rem;
    }
}